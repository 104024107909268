<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>财务管理</a-breadcrumb-item>
              <a-breadcrumb-item>结算管理</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div class="Enter-top-right"></div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="clearlist"
              :rowClassName="rowClassName"
            >
              <span slot="state" slot-scope="text">
                <a-badge
                  :status="text | stateTFilter"
                  :text="text | stateFilter"
                />
              </span>
              <span slot="type" slot-scope="text">
                <a-badge
                  :status="text | typeTFilter"
                  :text="text | typeFilter"
                />
              </span>
              <a slot="stores" slot-scope="text">
                <a-popover title="铺位列表">
                  <template slot="content">
                    <p class="text-store" v-for="item in text" :key="item.id">
                      {{ item.buildingNameSnapshot }}&nbsp;
                      {{ item.floorNameSnapshot }}&nbsp;
                      {{ item.storeNameSnapshot }}
                    </p>
                  </template>
                  <span>
                    {{ text[0].buildingNameSnapshot }}
                    {{ text[0].floorNameSnapshot }}
                    {{ text[0].storeNameSnapshot }}
                  </span>
                </a-popover>
              </a>
              <template slot="partyBName" slot-scope="text, record">
                <span
                  v-if="record.partyBName == null || record.partyBName == ''"
                >
                  <span v-if="record.partyBType == 0">{{
                    record.partyBNameE | text
                  }}</span>
                  <span v-else-if="record.partyBType == 1">{{
                    record.partyBNameP | text
                  }}</span>
                </span>
                <span v-else>
                  <span v-if="record.partyBName.length <= 8">{{
                    record.partyBName | text
                  }}</span>
                  <a-tooltip v-else>
                    <template slot="title">
                      {{ record.partyBName }}
                    </template>
                    {{ record.partyBName.substr(0, 8) + '..' }}
                  </a-tooltip>
                </span>
              </template>
              <span slot="clearTime" slot-scope="text">
                {{ text | time }}
              </span>
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">调整</a>
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
        <a-modal
          :visible="visible"
          okText="确定"
          cancelText="取消"
          title="调整结算"
          @ok="onSubmit"
          @cancel="onClose"
        >
          <a-form-model
            ref="ruleForm"
            :rules="rules"
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="租金">
              <a-input disabled="disabled" v-model="form.feeRent" />
            </a-form-model-item>
            <a-form-model-item label="物业费">
              <a-input disabled="disabled" v-model="form.feeProperty" />
            </a-form-model-item>
            <a-form-model-item label="保证金">
              <a-input disabled="disabled" v-model="form.surety" />
            </a-form-model-item>
            <a-form-model-item label="优惠折扣">
              <a-input disabled="disabled" v-model="form.discount" />
            </a-form-model-item>
            <a-form-model-item label="已付金额">
              <a-input disabled="disabled" v-model="form.payedRent" />
            </a-form-model-item>
            <a-form-model-item label="待结算金额">
              <a-input disabled="disabled" v-model="form.clear" />
            </a-form-model-item>
            <a-form-model-item ref="adjust" prop="adjust" label="补缴金额">
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.adjust"
              />
            </a-form-model-item>
            <a-form-model-item ref="refund" prop="refund" label="退款金额">
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.refund"
              />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '合同编号',
    dataIndex: 'sign.code',
  },
  {
    title: '承租方',
    dataIndex: 'sign.partyBName',
    scopedSlots: { customRender: 'sign.partyBName' },
  },
  {
    title: '铺位',
    dataIndex: 'stores',
    scopedSlots: { customRender: 'stores' },
  },
  {
    title: '待结算金额/元',
    dataIndex: 'clear',
  },
  {
    title: '补缴金额/元',
    dataIndex: 'adjust',
  },
  {
    title: '退款金额/元',
    dataIndex: 'refund',
  },
  {
    title: '结算人',
    dataIndex: 'clearByName',
  },
  {
    title: '结算时间',
    dataIndex: 'clearTime',
    scopedSlots: { customRender: 'clearTime' },
  },
  {
    title: '调整状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '终止状态',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const typeMap = {
  0: {
    status: 'success',
    text: '到期终止',
  },
  1: {
    status: 'error',
    text: '提前终止',
  },
}
const stateMap = {
  0: {
    status: 'processing',
    text: '未调整',
  },
  1: {
    status: 'success',
    text: '已调整',
  },
}

import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      visible: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },

      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      clearlist: [],
      Id: '',
      form: {
        id: '',
        feeRent: 0,
        adjust: 0,
        refund: 0,
        feeProperty: '',
        surety: '',
        discount: '',
        payedRent: '',
        clear: '',
      },
      rules: {
        adjust: [
          {
            required: true,
            message: '补缴金额不能为空!',
            trigger: 'blur',
          },
        ],
        refund: [
          {
            required: true,
            message: '退款金额不能为空!',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0] + ' ' + times[1]
      }
    },
    typeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return typeMap[type].text
      }
    },
    typeTFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return typeMap[type].status || ''
      }
    },
    stateFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return stateMap[type].text
      }
    },
    stateTFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return stateMap[type].status || ''
      }
    },
    text(text) {
      if (text.length <= 8 || text == null) {
        return text
      } else {
        return text.substr(0, 8) + '..'
      }
    },
  },
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (value == 'state') {
        this.params[this.value].op = '='
      }
      this.key = ''
      this.getfinanceclear()
    },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form = {
        id: '',
        feeRent: 0,
        adjust: 0,
        refund: 0,
        feeProperty: '',
        surety: '',
        discount: '',
        payedRent: '',
        clear: '',
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.putfinanceclear(this.form)
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    // 编辑
    onEdit(item) {
      //   console.log(item);
      this.Id = item.id
      this.visible = true
      this.form.id = item.id
      this.form.adjust = item.adjust == null ? 0 : item.adjust
      this.form.refund = item.refund == null ? 0 : item.refund
      this.form.feeProperty = item.feeProperty
      this.form.surety = item.surety
      this.form.discount = item.discount
      this.form.payedRent = item.payedRent
      this.form.feeRent = item.feeRent
      this.form.clear = item.clear
    },
    async putfinanceclear(form) {
      try {
        const res = await http.putfinanceclear(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.getfinanceclear()
      // console.log(this.params.current);
    },
    async getfinanceclear() {
      try {
        const res = await http.getfinanceclear(this.params)
        const { success, data } = res.data
        if (success) {
          this.clearlist = data.clears.records
          this.total = data.clears.total
          //   console.log(data.modules);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getfinanceclear()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getfinanceclear()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.text-store {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 0;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
